import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import Slide from '@material-ui/core/Slide';

const initialState = {
  events: [],
  status: 'idle',
  error: null,
  uploadedAttachments: {
    status: '',
    data: {},
    error: null,
  },
};

export const fetchEvents = createAsyncThunk('events/fetchEvents', async (offset) => {
  let data;
  try {
    const response = await axios.get(`/instructor/events?offset=${offset}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const uploadAttachmentsToEvent = createAsyncThunk(
  'events/uploadAttachmentsToEvent',
  async (query, thunkAPI) => {
    const { contentId, values } = query;
    let data;
    try {
      const response = await axios.post(`/instructor/events/${contentId}`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('event-modal'));
        window.location = '/seances-en-direct';
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteAttachmentsFromEvent = createAsyncThunk(
  'events/deleteAttachmentsFromEvent',
  async (query, thunkAPI) => {
    const { contentFileId } = query;
    let data;
    try {
      const response = await axios.delete(`/instructor/file/${contentFileId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('event-modal'));
        window.location = '/seances-en-direct';
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchEvents.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action.payload.length > 0) {
        const newData = action.payload.map((item) => {
          return {
            ...item,
            start_date: item.event.start_time,
            end_date: item.event.end_time,
          };
        });
        state.events = newData;
      } else {
        state.events = action.payload;
      }
    },
    [fetchEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [uploadAttachmentsToEvent.pending]: (state, action) => {
      state.uploadedAttachments.status = 'loading';
    },
    [uploadAttachmentsToEvent.fulfilled]: (state, action) => {
      state.uploadedAttachments.status = 'succeeded';
    },
    [uploadAttachmentsToEvent.fulfilled]: (state, action) => {
      state.uploadedAttachments.status = 'failed';
    },
    [deleteAttachmentsFromEvent.fulfilled]: (state, action) => {},
  },
});

export const reducer = slice.reducer;

export default slice;
