import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import { updateContent } from './contents';
import Slide from '@material-ui/core/Slide';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

const initialState = {
  videos: [],
  video: {
    data: '',
    status: 'idle',
    error: null,
  },
  meta: {},
  status: 'idle',
  error: null,
};

export const fetchVideos = createAsyncThunk(
  'videos/fetchVideos',
  async ({ page, instructorId, search }) => {
    let data;
    try {
      const response = await axios.get(
        `/instructor/content-videos?page=${page}&search=content.instructor.id:${instructorId};content.name:${search}&searchJoin=and&orderBy=id&sortedBy=desc`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addNewVideo = createAsyncThunk('videos/addNewVideo', async (query, thunkAPI) => {
  const { values } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-videos`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-video-modal'));
      // console.log(data.payload);
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editVideo = createAsyncThunk('videos/editVideo', async (query, thunkAPI) => {
  const { values, videoId, chapterSlug, external } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-videos/${videoId}`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-video-modal'));
      if (external) {
        thunkAPI.dispatch(updateContent('video', data.payload, chapterSlug));
      }
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteVideo = createAsyncThunk('videos/deleteVideo', async (query, thunkAPI) => {
  const { videoId } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.delete(`/instructor/content-videos/${videoId}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());
      window.location = '/videos';
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteAttachmentsFromVideo = createAsyncThunk(
  'videos/deleteAttachmentsFromVideo',
  async (query, thunkAPI) => {
    const { contentFileId } = query;
    let data;
    try {
      const response = await axios.delete(`/instructor/file/${contentFileId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('add-video-modal'));
        window.location = '/videos';
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const getTab = (video) => {
  if (video.content.chapter_contents.length === 0) {
    return null;
  }
  // if (video.content.chapter_contents[0].tab === null) {
  //   return '1';
  // }
  return video.content.chapter_contents[0].tab;
};

export const getVideoTab = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.tab != null) {
    return selectedChapter.tab;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section.tab;
  }
  return null;
};

export const getVideoSection = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section;
  }
  return null;
};

export const getVideoInformations = (data) => {
  if (data.content.content_videos.length > 0) {
    return data.content.content_videos;
  }
  return [
    {
      id: data.id,
      video_id: data.video_id,
      url: data.url,
      duration: data.duration,
      created_at: data.created_at,
    },
  ];
};

export const formatVideo = (data, chapterSlug) => {
  const selectedChapter = data.content.chapter_contents.find(
    (chapter) => chapter?.chapter?.slug === chapterSlug
  );
  const newData = {
    id: data.content.id,
    slug: data.content.slug,
    created_at: data.content.created_at,
    title: data.content.name,
    description: data.content.description,
    is_active: data.content.active,
    is_draft: data.content.is_draft,
    position: chapterSlug != null ? selectedChapter.position : data.content.position,
    type: data.content.type,
    tab: getVideoTab(data.content.chapter_contents, chapterSlug),
    section: getVideoSection(data.content.chapter_contents, chapterSlug),
    teacher: data.content.instructor,
    chapters: data.content.chapter_contents,
    levels: data.content.content_student_levels,
    scope: data.content.scope,
    video: getVideoInformations(data),
    attachments: data.content.content_files,
  };
  return newData;
};

const slice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVideos.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchVideos.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const newVideos = action.payload.payload.map((video) => {
        return formatVideo(video);
      });
      state.videos = newVideos;
      state.meta = action.payload.meta;
    },
    [fetchVideos.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addNewVideo.fulfilled]: (state, action) => {
      state.video.status = 'succeeded';
      const data = action.payload;
      state.videos.push(formatVideo(data));
    },
    [addNewVideo.pending]: (state, action) => {
      state.video.status = 'loading';
    },
    [addNewVideo.rejected]: (state, action) => {
      state.video.status = 'failed';
      state.video.error = action.payload;
    },
    [editVideo.fulfilled]: (state, action) => {
      state.video.status = 'succeeded';
      if (action.meta.arg.external) {
        return;
      }
      const data = action.payload;
      const index = state.videos.findIndex((item) => item.id === action.payload.content.id);
      state.videos[index] = formatVideo(data);
      state.status = 'succeeded';
    },
    [editVideo.pending]: (state, action) => {
      state.video.status = 'loading';
    },
    [editVideo.rejected]: (state, action) => {
      state.video.status = 'failed';
      state.video.error = action.payload;
    },
    [deleteVideo.fulfilled]: (state, action) => {
      const data = action.payload;
    },
    [deleteVideo.pending]: (state, action) => {
      state.video.status = 'loading';
    },
    [deleteVideo.rejected]: (state, action) => {
      state.video.status = 'failed';
      state.video.error = action.payload;
    },
    [deleteAttachmentsFromVideo.fulfilled]: (state, action) => {
      const data = action.payload;
      state.videos.push(formatVideo(data));
    },
  },
});

export const reducer = slice.reducer;

export default slice;
