import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';
import { closeModal } from './modals';
import { formatVideo } from './videos';
import { formatMagazine } from './magazines';
import { formatQcm } from './qcm';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

/*
---------- ⚠️ slice needs to be refactored / coded under time pressure 🤡 / 
*/

const initialState = {
  contents: [],
  status: 'idle',
  deleteStatus: 'idle',
  error: null,
};

const getTab = (tabId, sectionId, sections) => {
  if (tabId === null && sectionId === null) {
    return '1';
  }
  if (tabId === null && sectionId !== null) {
    const newTab = sections.find((item) => item.id.toString() === sectionId.toString());
    return newTab?.tab?.id.toString();
  }
  return tabId;
};

export const fetchContents = createAsyncThunk('contents/fetchContents', async (chapterSlug) => {
  let data;
  try {
    const response = await axios.get(`/instructor/${chapterSlug}/content`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const updateContentsPositions = createAsyncThunk(
  'contents/updateContentsPositions',
  async (query, thunkAPI) => {
    const { values } = query;
    let data;
    try {
      const response = await axios.put(`/instructor/update-position/contents`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteAttachmentsFromContent = createAsyncThunk(
  'contents/deleteAttachmentsFromContent',
  async (query, thunkAPI) => {
    const { contentFileId, chapterSlug, subjectSlug, isMagazine } = query;
    let data;
    try {
      const response = await axios.delete(`/instructor/file/${contentFileId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        if (isMagazine === true) {
          thunkAPI.dispatch(closeModal('add-magazine-modal'));
        } else {
          thunkAPI.dispatch(closeModal('add-video-modal'));
        }
        window.location = `/matieres/${subjectSlug}/chapitres/${chapterSlug}/contenu`;
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteContent = createAsyncThunk('contents/deleteContent', async (query, thunkAPI) => {
  const { contentId } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.delete(`/instructor/content/${contentId}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(removeContent(contentId));
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());

      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    updatePosition: (state, action) => {
      const { id, position, tab } = action.payload;

      let newArray = state.contents.contents
        .slice()
        .filter((item) => item.tab?.id === tab && item.content_level === 1)
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((item, index) => {
          return { ...item, position: index };
        });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.contents.contents = [
        ...newArray,
        ...state.contents.contents.filter(
          (item) => item.tab?.id === tab && item.content_level === 2
        ),
        ...state.contents.contents.filter((item) => item.tab?.id !== tab),
      ];
    },
    moveToSection: (state, action) => {
      const { id, section, position } = action.payload;

      const selectedItem = state.contents.contents.find((item) => item.id === parseInt(id, 10));

      const selectedSection = current(state).contents.contents.find(
        (item) => item.id === parseInt(section, 10)
      );

      selectedItem.section = {
        id: selectedSection.id,
        name: selectedSection.title,
        position: selectedSection.position,
        tab: selectedSection.tab,
      };
      selectedItem.content_level = 2;

      state.contents.contents = [
        selectedItem,
        ...state.contents.contents.filter((item) => item.id !== parseInt(id, 10)),
      ];

      let newArray = state.contents.contents
        .slice()
        .filter((item) => item.section?.id === parseInt(section, 10))
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((item, index) => {
          return { ...item, position: index };
        });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.contents.contents = [
        ...newArray,
        ...state.contents.contents.filter((item) => item.section?.id !== parseInt(section, 10)),
      ];
    },
    updateSectionItemPosition: (state, action) => {
      const { id, section, position } = action.payload;

      let newArray = state.contents.contents
        .slice()
        .filter((item) => item.section?.id === parseInt(section, 10))
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((item, index) => {
          return { ...item, position: index };
        });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.contents.contents = [
        ...newArray,
        ...state.contents.contents.filter((item) => item.section?.id !== parseInt(section, 10)),
      ];
    },
    moveToMain: (state, action) => {
      const { id, section, position, tab } = action.payload;

      const selectedItem = state.contents.contents.find((item) => item.id === parseInt(id, 10));

      const selectedTab = current(state).contents.tabs.find((item) => item.id === tab);

      selectedItem.tab = {
        id: selectedTab.id,
        name: selectedTab.name,
        shortName: selectedTab.shortName,
        position: selectedTab.position,
      };
      selectedItem.section = null;
      selectedItem.content_level = 1;

      state.contents.contents = [
        selectedItem,
        ...state.contents.contents.filter((item) => item.id !== parseInt(id, 10)),
      ];

      let newArray = state.contents.contents
        .slice()
        .filter((item) => item.tab?.id === tab && item.content_level === 1)
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((item, index) => {
          return { ...item, position: index };
        });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));
      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.contents.contents = [
        ...newArray,
        ...state.contents.contents.filter(
          (item) => item.tab.id === tab && item.content_level === 2
        ),
        ...state.contents.contents.filter((item) => item.tab.id !== tab),
      ];
    },
    addSection: (state, action) => {
      const { section, edit } = action.payload;

      let newArray = [];

      if (edit) {
        newArray = state.contents.contents.filter((item) => item.id !== section.id);
      } else {
        newArray = state.contents.contents;
      }

      state.contents.contents = [
        ...newArray,
        {
          id: section.id,
          title: section.name,
          tab: section.tab,
          is_draft: section?.is_draft,
          position: parseInt(section.position, 10),
          content_type: 'section',
          content_level: 1,
        },
      ];
    },
    removeSection: (state, action) => {
      const { sectionId, sectionPosition } = action.payload;

      let newArray = [];

      newArray = state.contents.contents.filter((item) => item.id !== sectionId);

      state.contents.contents = newArray.map((item) => {
        if (item.content_type === 'content') {
          if (item.section != null) {
            if (item.section?.id === sectionId) {
              return { ...item, position: sectionPosition, content_level: 1, section: null };
            }
          }
        }
        return item;
      });
    },
    updateContent: (state, action) => {
      const { type, content, chapterSlug, chapterId } = action.payload;

      let index = null;

      // find content index
      index = state.contents.contents.findIndex(
        (item) => item.id.toString() === content?.content?.id.toString()
      );

      const index1 = current(state).contents.contents.find(
        (item) => item.id.toString() === content?.content?.id.toString()
      );

      // console.table({
      //   type,
      //   chapterSlug,
      //   chapterId,
      //   index,
      // });

      let data = null;

      if (type === 'video') {
        data = formatVideo(content, chapterSlug);
      }
      if (type === 'magazine') {
        const editedContent = {
          ...content,
          position: current(state).contents.contents[index].position,
          content: {
            ...content.content,
            position: current(state).contents.contents[index].position,
          },
        };
        data = formatMagazine(editedContent, chapterSlug);
      }
      if (type === 'qcm') {
        data = formatQcm(content, chapterSlug);
      }

      // console.table({
      //   type,
      //   content,
      //   chapterSlug,
      //   index,
      //   data,
      // });

      state.contents.contents[index] = {
        ...data,
        content_type: 'content',
        content_level: data.section == null ? 1 : 2,
      };
    },
    removeContent: (state, action) => {
      const { contentId } = action.payload;

      let newArray = [];

      newArray = state.contents.contents.filter((item) => item.id !== contentId);

      state.contents.contents = newArray.map((item) => {
        if (item.content != null) {
          if (item.content?.id === contentId) {
            return { ...item };
          }
        }
        return item;
      });
    },
  },
  extraReducers: {
    [fetchContents.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchContents.fulfilled]: (state, action) => {
      state.status = 'succeeded';

      const chapterSlug = action.meta.arg;

      const newContents = action.payload.contents.map((content) => {
        if (content.content.type.id === 3) {
          const data = formatMagazine(content.content, chapterSlug);
          return { ...data, content_type: 'content', content_level: data.section == null ? 1 : 2 };
        }
        if (content.content.type.id === 4) {
          const data = formatQcm(content.content, chapterSlug);
          return { ...data, content_type: 'content', content_level: data.section == null ? 1 : 2 };
        }
        const data = formatVideo(content, chapterSlug);
        return { ...data, content_type: 'content', content_level: data.section == null ? 1 : 2 };
      });

      const newSections = action.payload.sections.map((section) => {
        return {
          id: section.id,
          title: section.name,
          is_draft: section.is_draft,
          tab: section.tab,
          position: parseInt(section.position, 10),
          content_type: 'section',
          content_level: 1,
        };
      });

      const newTabs = action.payload.tabs.map((tab) => {
        return {
          id: parseInt(tab.id, 10),
          name: tab.name,
          shortName: tab.shortName,
          position: parseInt(tab.position, 10),
        };
      });

      state.contents = {
        subject: action.payload.subject,
        division: action.payload.division,
        chapter: action.payload.chapter,
        tabs: newTabs,
        contents: newContents
          .concat(newSections)
          .slice()
          .sort((a, b) => (a.name < b.name ? 1 : -1))
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
      };
    },
    [fetchContents.rejected]: (state, action) => {
      state.deleteStatus = 'failed';
      state.error = action.payload;
    },

    [deleteContent.pending]: (state, action) => {
      state.deleteStatus = 'loading';
    },
    [deleteContent.fulfilled]: (state, action) => {
      state.deleteStatus = 'succeeded';
    },
    [deleteContent.rejected]: (state, action) => {
      state.deleteStatus = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const updatePosition = (id, position, tab) => (dispatch) => {
  dispatch(slice.actions.updatePosition({ id, position, tab }));
};

export const moveToSection = (id, section, position) => (dispatch) => {
  dispatch(slice.actions.moveToSection({ id, section, position }));
};

export const moveToMain = (id, section, position, tab) => (dispatch) => {
  dispatch(slice.actions.moveToMain({ id, section, position, tab }));
};

export const updateSectionItemPosition = (id, section, position) => (dispatch) => {
  dispatch(slice.actions.updateSectionItemPosition({ id, section, position }));
};

export const addSection = (section, edit) => (dispatch) => {
  dispatch(slice.actions.addSection({ section, edit }));
};

export const removeSection = (sectionId, sectionPosition) => (dispatch) => {
  dispatch(slice.actions.removeSection({ sectionId, sectionPosition }));
};

export const updateContent = (type, content, chapterSlug, chapterId) => (dispatch) => {
  dispatch(slice.actions.updateContent({ type, content, chapterSlug, chapterId }));
};

export const removeContent = (contentId) => (dispatch) => {
  dispatch(slice.actions.removeContent({ contentId }));
};

export default slice;
